import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropertyCard from '../components/PropertyCard';
import DropDown from '../components/Dropdown';
import Loader from '../components/Loader'; // Import the Loader component
import useCity from '../hooks/useCity';
import useListing from '../hooks/useListing';
import { Listing } from '../models/Listing';
import { City } from '../models/City';
import ListingDetails from '../components/ListingDetails'; // Import the ListingDetails component
import Logo from '../../src/BektuAI.svg';  // Adjust the relative path
import useLogin from '../hooks/useLogin';

const Home: React.FC = () => {
  const [listings, setListings] = useState<Listing[]>([]);
  const [refreshing, setRefreshing] = useState(false); // For initial loading
  const [loadingMore, setLoadingMore] = useState(false); // For infinite scrolling loading
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null); // State for selected listing
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [showLoginFooter, setShowLoginFooter] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const { login, loading, error } = useLogin();

  // Pagination states
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loaderDivRef = useRef<HTMLDivElement | null>(null); // Ref for the loader div

  // Fetch cities on mount
  useEffect(() => {
    fetchCities();
  }, []);

  // Fetch listings whenever a city is selected
  useEffect(() => {
    if (selectedCity) {
      setPage(1); // Reset to first page when city changes
      setListings([]); // Clear the listings when a new city is selected
      fetchListings(selectedCity, 1, true); // Fetch first page of listings for the selected city, refreshing
    }
  }, [selectedCity]);

  // Fetch listings when the page changes (triggered by scrolling)
  useEffect(() => {
    if (page > 1 && selectedCity) {
      fetchListings(selectedCity, page, false); // Fetch additional listings for pagination (not refreshing)
    }
  }, [page]);

  // Set up IntersectionObserver to load more listings when reaching the bottom
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && !loadingMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }, { threshold: 1 });

    // Observe the loader div
    if (loaderDivRef.current) {
      observer.observe(loaderDivRef.current);
    }

    return () => {
      if (loaderDivRef.current) observer.unobserve(loaderDivRef.current);
    };
  }, [hasMore, loadingMore]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const fetchListings = async (cityName: string, page: number = 1, isRefreshing: boolean = false) => {
    if (isRefreshing) {
      setRefreshing(true);
    } else {
      setLoadingMore(true); // Set loadingMore when fetching additional listings during scroll
    }

    try {
      const response = await useListing.getListings(cityName, page);

      // Check if the response contains an array for listings
      if (Array.isArray(response.listings)) {
        // Append new listings to the existing ones
        setListings((prevListings) => [...prevListings, ...response.listings]);

        // If fewer listings are returned than the limit, assume no more data
        if (response.listings.length < 5) {
          setHasMore(false); // No more listings to fetch
        }
      } else {
        console.error('Listings is not an array:', response.listings);
        setListings([]); // Fallback to an empty array if listings is not valid
      }
    } catch (error) {
      console.error('Error fetching listings:', error);
    } finally {
      setTimeout(() => {
        if (isRefreshing) {
          setRefreshing(false);
        } else {
          setLoadingMore(false); // Stop loading more when infinite scroll completes
        }
      }, 1000); // Add 1 second delay to prevent loader flickering
    }
  };

  const fetchCities = useCallback(async () => {
    try {
      const fetchedCities = await useCity.getCities();
      setCities(fetchedCities);
      if (fetchedCities.length > 0) {
        setSelectedCity(fetchedCities[0].name); // Set the first city by default
      }
    } catch (error) {
      console.error('Failed to fetch cities:', error);
    }
  }, []);

  const handleSelectCity = (cityName: string) => {
    setSelectedCity(cityName);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top smoothly
  };

  const handlePropertyClick = (listing: Listing) => {
    setSelectedListing(listing);
    setModalVisible(true); // Show the modal-like overlay
  };

  const handleCloseModal = () => {
    setSelectedListing(null);
    setModalVisible(false); // Close the modal
  };

  const handleLoginClick = () => {
    setShowLoginFooter(true); // Show the footer when login button is clicked
  };

  const handleEmailSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      setSuccessMessage(''); // Clear success message if there's an error
    } else {
      setEmailError(''); // Clear error

      const result = await login(email); // Call the custom hook to make the API request
      if (result) {
        console.log('Login successful:', result); // Handle success (e.g., token)
        setSuccessMessage('Check your email for the magic link!'); // Set success message
        setEmailError(''); // Clear any email error if it exists
      } else {
        setSuccessMessage(''); // Clear success message if there's no result
      }
    }
  };

  return (
    <div className="min-h-screen bg-white p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 pt-24">
      <style>{`
        /* CSS for modal transition */
        .modal-background {
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
        }

        .modal-background.open {
          opacity: 1;
        }

        .modal-slide {
          transform: translateY(100%);
          transition: transform 0.5s ease-in-out;
        }

        .modal-slide.open {
          transform: translateY(0);
        }
      `}</style>
      <div className="fixed top-0 left-0 w-full bg-white p-4 sm:p-6 z-10 shadow-md flex flex-col sm:flex-row sm:justify-between items-center">
        {/* Modal-like overlay for listing details */}
        {isModalVisible && selectedListing && (
        <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal-background ${isModalVisible ? 'open' : ''}`}>
            <ListingDetails listing={selectedListing} onClose={handleCloseModal} /> {/* Pass the selected listing to the modal */}
        </div>
      )}
        {/* Add Logo and Dropdown */}
        <div className="flex items-center justify-start w-full sm:w-auto">
          <img
            src={Logo} 
            alt="Logo"
            className="h-12 w-20 sm:h-16 sm:w-16 md:h-20 lg:h-24 xl:h-28 mr-4"
          />
          <DropDown cities={cities} selectedCity={selectedCity} onSelectCity={handleSelectCity} />
        </div>
      </div>

      {/* Show Loader while fetching the first page */}
      {refreshing && page === 1 ? (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      ) : (
        <div className={`duration-1000 ${isModalVisible ? 'opacity-0' : 'opacity-100'}`}>
          <div className="grid grid-cols-1 gap-4 sm:gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 my-0">
            {listings.map((listing) => (
              <PropertyCard key={listing.id} listing={listing} onClick={() => handlePropertyClick(listing)} onLoginClick={handleLoginClick} />
            ))}
          </div>
        </div>
      )}

      {/* Loader for infinite scrolling */}
      {hasMore && (
        <div ref={loaderDivRef} className="flex justify-center items-center h-20">
          {loadingMore && <Loader />}
        </div>
      )}

      {/* Login footer */}
      {showLoginFooter && (
      <div className="w-full fixed bottom-0 left-0 right-0 bg-white p-8 shadow-lg">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border border-gray-300 p-2 rounded-lg mb-4 md:mb-0"
          />
          {/* Display error message */}
          {emailError && (
            <p className="text-red-500 text-sm mb-4">{emailError}</p>
          )}
          {/* Display success message */}
          {successMessage && (
            <p className="text-green-500 text-sm mb-4">{successMessage}</p>
          )}
          {/* Display error from the login hook */}
          {error && (
            <p className="text-red-500 text-sm mb-4">{error}</p>
          )}
          <button
            className={`w-full bg-blue-500 text-white py-4 px-4 rounded-lg ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleEmailSubmit}
            disabled={loading} // Disable the button while loading
          >
            <span>{loading ? 'Submitting...' : 'Explore property insights'}</span>
          </button>
        </div>
      </div>
    )}
    </div>
  );
};

export default Home;
